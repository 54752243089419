<template>
   <div class="container">
      <el-container class="wrap">
        <el-main class="mains">
          <div class="main_content">
            <!-- 阅读选择 -->
            <yue-du-xuan-ze :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '阅读选择'" @submitAnswer="submitAnswer"></yue-du-xuan-ze>
            <!-- 补全单词 -->
            <bu-quan-dan-ci :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '补全单词'" @submitAnswer="submitAnswer" ref="buquandanci"></bu-quan-dan-ci>
            <!-- 听写句子 -->
            <ting-xie-ju-zi :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '听写句子'" @submitAnswer="submitAnswer"></ting-xie-ju-zi>
            <!-- 互动听力 -->
            <hu-dong-ting-li :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '互动听力'" @submitAnswer="submitAnswer" ref="hudongtingli"></hu-dong-ting-li>
            <!-- 朗读句子 -->
            <lang-du-ju-zi :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '朗读句子'" @submitAnswer="submitAnswer"></lang-du-ju-zi>
            <!-- 听题演讲 -->
            <ting-ti-yan-jiang :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '听题演讲'" @submitAnswer="submitAnswer"></ting-ti-yan-jiang>
            <!-- 看图说话 -->
            <kan-tu-shuo-hua :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '看图说话'" @submitAnswer="submitAnswer"></kan-tu-shuo-hua>
            <!-- 阅读说话 -->
            <yue-du-shuo-hua :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '阅读说话'" @submitAnswer="submitAnswer"></yue-du-shuo-hua>
            <!-- 口语样本 -->
            <kou-yu-yang-ben :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '口语样本'" @submitAnswer="submitAnswer"></kou-yu-yang-ben>
            <!-- 互动阅读 -->
            <hu-dong-yue-du :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '互动阅读'" @submitAnswer="submitAnswer" ref="hudongyuedu"></hu-dong-yue-du>
            <!-- 阅读填空 -->
            <yue-du-tian-kong :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '阅读填空'" @submitAnswer="submitAnswer" ref="yuedutiankong"></yue-du-tian-kong>
            <!-- 看图写作 -->
            <kan-tu-xie-zuo :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '看图写作'" @submitAnswer="submitAnswer"></kan-tu-xie-zuo>
            <!-- 写作样本 -->
            <xie-zuo-yang-ben :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '写作样本'" @submitAnswer="submitAnswer"></xie-zuo-yang-ben>
            <!-- 互动写作 -->
            <hu-dong-xie-zuo :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '互动写作'" @submitAnswer="submitAnswer"></hu-dong-xie-zuo>
          </div>
        </el-main>
      </el-container>
   </div>
</template>
<script>
import BuQuanDanCi from '../components/BuQuanDanCi.vue';
import HuDongTingLi from '../components/HuDongTingLi.vue';
import HuDongXieZuo from '../components/HuDongXieZuo.vue';
import HuDongYueDu from '../components/HuDongYueDu.vue';
import KanTuShuoHua from '../components/KanTuShuoHua.vue';
import KanTuXieZuo from '../components/KanTuXieZuo.vue';
import KouYuYangBen from '../components/KouYuYangBen.vue';
import LangDuJuZi from '../components/LangDuJuZi.vue';
import TingTiYanJiang from '../components/TingTiYanJiang.vue';
import TingXieJuZi from '../components/TingXieJuZi.vue';
import XieZuoYangBen from '../components/XieZuoYangBen.vue';
import YueDuShuoHua from '../components/YueDuShuoHua.vue';
import YueDuTianKong from '../components/YueDuTianKong.vue';
import YueDuXuanZe from '../components/YueDuXuanZe.vue';


export default {
  components: {
    YueDuXuanZe,
    BuQuanDanCi,
    TingXieJuZi,
    HuDongTingLi,
    LangDuJuZi,
    TingTiYanJiang,
    YueDuShuoHua,
    KouYuYangBen,
    HuDongYueDu,
    YueDuTianKong,
    KanTuXieZuo,
    XieZuoYangBen,
    HuDongXieZuo,
    KanTuShuoHua
  },
  data() {
      return {
        // 考试卷列表
        examList: [],
        current: 0,
        //考卷信息
        log_id: '',
        questionDetail: {
          topic_type_name: ""
        },
      }
   },
   mounted() {
    this.getExamList()
    
    //**测试使用
    // this.examList = JSON.parse(localStorage.getItem('examList')).list;
    // this.current = 33;
    // this.getExamDetail()
   },
   methods: {
      //播放音频
      playMusic() {
        //播放次数-减一
        if(this.play_number == 0) return
        if(this.play_number>0) this.play_number--;
        this.playmp3('',3,this.questionDetail);
      },
      getExamList() {
        this.$api.getExamList({}).then(res => {
          if(res.code == 1) {
              this.examList = res.data.list;
              this.log_id = res.data.log_id;
              localStorage.setItem('log_detail_id',res.data.log_id)
              //测试使用
              // localStorage.setItem('examList',JSON.stringify(res.data))
              

              //默认赋值第一道题
              if(res.data.list.length > 0) {
                this.getExamDetail();
              }
          }else {
            this.$message({
                message: res.msg,
                type: 'error'
              });
          }
        })
      },
      getExamDetail() {
         
        this.$api.getExamDetail({
          id: this.examList[this.current].id,
          type: this.examList[this.current].type,
          log_id: this.log_id,
          // id: this.examList[this.current].id,
          // type: this.examList[this.current].type,
          // log_id: 54,
        }).then(res => {
          if(res.code == 1) {
              this.questionDetail = res.data;
              if(this.questionDetail.topic_type_name == '阅读填空') {
                this.$nextTick(() => {
                  this.$refs.yuedutiankong.calcData();
                })
              }
              if(this.questionDetail.topic_type_name == '互动阅读') {
                this.$nextTick(() => {
                  this.$refs.hudongyuedu.calcData();
                })
              }
              if(this.questionDetail.topic_type_name == '补全单词') {
                this.$nextTick(() => {
                  this.$refs.buquandanci.calcData();
                })
              }
              //如果是互动听力——多个小题选项
              if(this.questionDetail.topic_type_name == "互动听力") {
                this.questionDetail.problem_list.forEach((v,k) => {
                  v.is_choose = false;
                  v.choose_answer = "";
                  v.choose_answer_content = "";
                  v.options.forEach((m,n) => {
                    m.checked = false;
                  })
                })
                this.$nextTick(() => {
                  this.$refs.hudongtingli.calcData();
                })
                // console.log("problem_list",this.questionDetail.problem_list)
              }
            }
        })
      },
      //提交答案
      submitAnswer(e) {
       var data = {
          log_detail_id: this.questionDetail.log_detail_id,
          exam_time: e.supletime,
        };

        if(this.questionDetail.topic_type_name == '互动阅读') {
          data.son_list = e.answer;
        }else {
          data.answer = e.answer;
        }

        this.$api.submitAnswer(data).then(res => {
          if(res.code == 1) {
              if(parseInt(this.examList.length) > parseInt(this.current+1)) {
                this.current++;
                this.getExamDetail()
              }else if(parseInt(this.examList.length) == parseInt(this.current+1)) {
                this.$router.push({path: '/submit?log_detail_id=' + this.questionDetail.log_detail_id})
              }else {}
              this.$message({
                message: res.msg,
                type: 'success'
              });

            }
        })
      },
   }
}
</script>
<style  lang="scss" scoped>
.container,.wrap {
  width: 100%;
  height: 100vh;
  background: #F6F7FB;
}
.mains {
  height: calc(100% - 70px);
  padding-top: 62px;
  box-sizing: border-box;
}

.main_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 2px;
  padding: 30px 0;
  img {
    width: 148px;
    height: 156px;
  }
  .score {
    display: inline-block;
    padding: 0 24px;
    background: #F6F7FB;
    font-size: 30px; 
    font-weight: bold;
    color: $color-title2;
  }
  .confirm {
    width: 110px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    border-radius: 2px;
    background: $color-title2;
    color: #fff;
    font-size: 14px;
  }
}
</style>
